import React from 'react';
import ReactDOM from 'react-dom/client';
import SurveyComponent from './SurveyComponent';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>


    <Router>
      <Routes>
        <Route path="/survey/:id" element={<SurveyComponent />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>

  </React.StrictMode>
);


import React, { useState, useEffect } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css"; 
import { useParams } from "react-router-dom";


function SurveyComponent() {
  const [surveyModel, setSurveyModel] = useState(null);

  const { id } = useParams();



  useEffect(() => {
    const fetchData = async () => {
      try {

        const json = await import(`../public/surveys/j_${id}.json`);
        const theme = await import(`../public/surveys/t_${id}.json`);
        const survey = new Model(json);
        survey.applyTheme(theme);
        survey.onComplete.add((sender, options) => {

          const surveyData = sender.data;
          const submitUrl = '/submit';
          fetch(submitUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(surveyData),
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              console.log("Survey submitted successfully:", data);
              
            })
            .catch(error => {
              console.error("Error submitting survey:", error);
            });



        });

        setSurveyModel(survey);
      } catch (error) {
        console.error("Failed to fetch survey data:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!surveyModel) return <div>Loading survey...</div>;

  return <Survey model={surveyModel} />;
}

export default SurveyComponent;
